import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';
import { differenceInMinutes } from 'date-fns';
import { useTitle } from 'react-use';

import { EnumsEventType } from '@lp-lib/api-service-client/public';

import { EventScheduleOnD } from '../components/Event/Schedule/EventScheduleOnD';
import { PublicHomeLayoutV2 } from '../components/PublicHome/PublicHomeLayout';
import { PublicHomeProvider } from '../components/PublicHome/PublicHomeProvider';
import { useBootIntercom } from '../hooks/useBootIntercom';
import { useInstance } from '../hooks/useInstance';
import { apiService } from '../services/api-service';
import { fromDTOOrganizer } from '../utils/api-dto';
import { makeTitle } from '../utils/common';
import { DateUtils } from '../utils/date';
import { tokenWithRedirect } from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const { id } = action.params;
  if (!id) {
    throw new Error('Event ID is required');
  }

  const event = await tokenWithRedirect(
    async () => (await apiService.event.get(id)).data.event,
    action.request.url,
    { preferRedirect: 'login' }
  );

  const recommendedAttendees = await tokenWithRedirect(
    async () => (await apiService.event.getRecommendedAttendees()).data.results,
    action.request.url,
    { preferRedirect: 'login' }
  );

  return { event, recommendedAttendees };
}

function Container() {
  useBootIntercom(useInstance(() => ['/events']));
  useTitle(makeTitle('Update an Event'));

  const { event, recommendedAttendees } = useLoaderData<typeof clientLoader>();

  switch (event.type) {
    case EnumsEventType.EventTypeLive:
      return null;
    case EnumsEventType.EventTypeOnd:
      return (
        <EventScheduleOnD
          event={event}
          initData={{
            pack: event.gamePack,
            eventTime: DateUtils.ParseEventTime(event.startAt, event.timezone),
            attendees:
              event.attendees?.map((a) => ({
                kind: 'organizer',
                organizer: fromDTOOrganizer(a),
              })) || [],
            durationMin: differenceInMinutes(
              new Date(event.endAt),
              new Date(event.startAt)
            ),
            message: event.message,
          }}
          recommendedAttendees={recommendedAttendees}
        />
      );
    default:
      return null;
  }
}

export function Component() {
  return (
    <PublicHomeProvider>
      <PublicHomeLayoutV2 backgroundClassName='bg-black'>
        <Container />
      </PublicHomeLayoutV2>
    </PublicHomeProvider>
  );
}
